import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ActionType from "../../resources/enums";
import ConfirmationModal from "../commonModals/ConfirmationModal";
import helper from "../../utils/helper";
import ROUTES from "../../constant/routes";
import storage from "../../utils/storage";

import { IAuthReducerState } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/reducers/Index";
import { ADMIN_KEY, ARTIST_KEY } from "../../constant/commonConstants";

import logo from "../../assets/images/footer-logo.png";

import "./Header.scss";
import { loginUserByAdmin } from "../../services/user";
import toast from "../../utils/toastsMessage";
import AdminSettings from "../../container/admin/adminSettings/AdminSettings";

const IMAGE_URL = process.env.REACT_APP_BUCKET_BASE_URL;

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminTokenData = storage.get(ADMIN_KEY);
  const artistTokenData = storage.get(ARTIST_KEY);
  const { t: translation } = useTranslation();
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  const encryptId = helper.getEncryptId(authReducer.authData.id);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleLogOut = async () => {
    await dispatch({
      type: ActionType.LOGOUT,
    });
    storage.removeAll();
    (adminTokenData || artistTokenData) ? navigate(ROUTES.ADMIN_LOGIN) : navigate(ROUTES.LOGIN);
  };

  const handleAdminLoin = async () => {
    const res = await loginUserByAdmin({ token: adminTokenData ? adminTokenData : artistTokenData });

    if (res?.data?.success) {
      await dispatch({
        type: ActionType.LOGOUT,
      });
      storage.removeAll();

      const userData = res?.data?.data?.userInfo;
      const address = JSON.parse(res?.data?.data?.userInfo?.address);
      await dispatch({
        type: ActionType.LOGIN,
        payload: { ...userData, address },
      });
      await storage.set("__RTA__", userData?.token);
      await storage.set("authData", JSON.stringify(userData));

      navigate(ROUTES.USER_LIST);
    }
  };

  return (
    <div className="header">
      <AdminSettings/>
      <div className="container">
        <nav className="navbar navbar-expand-md header-bg">
          <Link className="navbar-brand" to={ROUTES.HOME}>
            <div>
              <img className="logo" src={logo} alt="" />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 72 72"
              id="emoji"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="color" />
              <g id="hair" />
              <g id="skin" />
              <g id="skin-shadow" />
              <g id="line">
                <line
                  x1="16"
                  x2="56"
                  y1="26"
                  y2="26"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="16"
                  x2="56"
                  y1="36"
                  y2="36"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="16"
                  x2="56"
                  y1="46"
                  y2="46"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </button>

          <div
            className="collapse navbar-collapse align-items-center justify-content-end"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav me-2">
              <li className="nav-item bd-right">
                <Link className="nav-link" to={ROUTES.PLANS}>
                  {translation("header_pricing")}
                </Link>
              </li>
              <li className="nav-item bd-right">
                <Link className="nav-link" to={ROUTES.CONTACT}>
                  {translation("contact_us")}
                </Link>
              </li>
            </ul>
            {(adminTokenData || artistTokenData) && (
              <button
                className="btn theme-button primary-btn me-4"
                type="button"
                onClick={() => handleAdminLoin()}
              >
                {translation(artistTokenData ? "back_to_artist" : "back_to_admin")}
              </button>
            )}

            <button
              className="btn theme-button primary-btn me-4"
              type="button"
              onClick={() => {
                if (authReducer.isLoggedIn) {
                  if (authReducer.authData.address?.address_line_1) {
                    navigate(ROUTES.UPLOAD_ARTWORK);
                  } else {
                    toast.error(translation("complete_profile_first"));
                  }
                } else {
                  navigate(ROUTES.LOGIN);
                }
              }}
            >
              {translation("header_upload_art")}
            </button>
            {!authReducer.isLoggedIn ? (
              <button
                type="button"
                className=" theme-button black-btn-outline me-3"
                onClick={() => navigate(ROUTES.LOGIN)}
              >
                {translation("login")}
              </button>
            ) : (
              <div className="form-group mb-0">
                <div className="header-profile">
                  {authReducer.authData.image ? (
                    <div className="user-box">
                      <img
                        src={`${IMAGE_URL}${authReducer?.authData?.image}`}
                        alt=""
                        className="default-image fix-img-sm border-theme  font-sm"
                      />
                      {authReducer.authData.address === null ? (
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="512"
                            viewBox="0 0 128 128"
                            width="512"
                          >
                            <g>
                              <path
                                d="m56.463 14.337-49.563 92.307c-2.8 5.217 1.273 11.356 7.537 11.356h99.126c6.264 0 10.338-6.139 7.537-11.356l-49.563-92.307c-3.106-5.783-11.968-5.783-15.074 0z"
                                fill="#ff0000"
                              />
                              <g fill="#fff">
                                <path d="m64 31.726a5.418 5.418 0 0 0 -5.5 5.45l1.017 44.289a4.422 4.422 0 0 0 4.483 4.261 4.422 4.422 0 0 0 4.482-4.261l1.018-44.289a5.418 5.418 0 0 0 -5.5-5.45z" />
                                <circle cx="64" cy="100.222" r="6" />
                              </g>
                            </g>
                          </svg>
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <div className="user-box">
                      <div className="default-image fix-img-sm border-theme  font-sm">
                        {helper.capitalize(
                          authReducer?.authData?.first_name?.charAt(0)
                        )}
                      </div>
                      {authReducer.authData.address === null ? (
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="512"
                            viewBox="0 0 128 128"
                            width="512"
                          >
                            <g>
                              <path
                                d="m56.463 14.337-49.563 92.307c-2.8 5.217 1.273 11.356 7.537 11.356h99.126c6.264 0 10.338-6.139 7.537-11.356l-49.563-92.307c-3.106-5.783-11.968-5.783-15.074 0z"
                                fill="#ff0000"
                              />
                              <g fill="#fff">
                                <path d="m64 31.726a5.418 5.418 0 0 0 -5.5 5.45l1.017 44.289a4.422 4.422 0 0 0 4.483 4.261 4.422 4.422 0 0 0 4.482-4.261l1.018-44.289a5.418 5.418 0 0 0 -5.5-5.45z" />
                                <circle cx="64" cy="100.222" r="6" />
                              </g>
                            </g>
                          </svg>
                        </span>
                      ) : null}
                    </div>
                  )}
                  <ul
                    className=" user-dropdown"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to={ROUTES.PROFILE}>
                        <svg
                          className="me-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 68.2 85"
                        >
                          <path
                            id="Profile"
                            d="M59.835,24.222A22.235,22.235,0,1,1,37.6,2,22.152,22.152,0,0,1,59.835,24.222ZM37.6,86C19.382,86,4,83.039,4,71.615S19.479,57.33,37.6,57.33c18.222,0,33.6,2.961,33.6,14.385S55.721,86,37.6,86Z"
                            transform="translate(-3.5 -1.5)"
                            stroke="#000"
                            strokeWidth="1"
                            fillRule="evenodd"
                            opacity="1"
                            fill="#FF9494"
                          />
                        </svg>
                        {translation("header_my_profile")}
                        {authReducer.authData.address === null ? (
                          <span className="profile-status">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="512"
                              viewBox="0 0 128 128"
                              width="512"
                            >
                              <g>
                                <path
                                  d="m56.463 14.337-49.563 92.307c-2.8 5.217 1.273 11.356 7.537 11.356h99.126c6.264 0 10.338-6.139 7.537-11.356l-49.563-92.307c-3.106-5.783-11.968-5.783-15.074 0z"
                                  fill="#ff0000"
                                />
                                <g fill="#fff">
                                  <path d="m64 31.726a5.418 5.418 0 0 0 -5.5 5.45l1.017 44.289a4.422 4.422 0 0 0 4.483 4.261 4.422 4.422 0 0 0 4.482-4.261l1.018-44.289a5.418 5.418 0 0 0 -5.5-5.45z" />
                                  <circle cx="64" cy="100.222" r="6" />
                                </g>
                              </g>
                            </svg>
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          if (authReducer.authData.address?.address_line_1) {
                            navigate(
                              authReducer.isLoggedIn
                                ? ROUTES.USER_ORDER_LIST.replace(
                                    ":userId",
                                    encryptId
                                  )
                                : ROUTES.LOGIN
                            );
                          } else {
                            toast.error(translation("complete_profile_first"));
                          }
                        }}
                      >
                        <svg
                          className="me-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 76.6 85"
                        >
                          <path
                            id="Document"
                            d="M23.2,2H58.4C71.376,2,78.6,9.476,78.6,22.286V65.672C78.6,78.692,71.376,86,58.4,86H23.2C10.434,86,3,78.692,3,65.672V22.286C3,9.476,10.434,2,23.2,2Zm1.134,19.572V21.53H36.89a3.3,3.3,0,0,1,0,6.594H24.336a3.276,3.276,0,0,1,0-6.552Zm0,25.536H57.264a3.278,3.278,0,0,0,0-6.556H24.336a3.278,3.278,0,0,0,0,6.556Zm0,19.194H57.264a3.319,3.319,0,0,0,0-6.594H24.336a3.294,3.294,0,0,0-3.15,1.554,3.339,3.339,0,0,0,0,3.528A3.262,3.262,0,0,0,24.336,66.3Z"
                            transform="translate(-2.5 -1.5)"
                            stroke="#000"
                            strokeWidth="1"
                            fillRule="evenodd"
                            opacity="1"
                            fill="#FF9494"
                          />
                        </svg>
                        {translation("header_my_orders")}
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setShowConfirmModal(!showConfirmModal)}
                        className="dropdown-item"
                      >
                        <svg
                          className="me-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 84 81.802"
                        >
                          <path
                            id="Logout"
                            d="M40.1,2.021A18.141,18.141,0,0,1,58.216,19.307l.02.854v3.816a3.068,3.068,0,0,1-6.107.416l-.028-.416V20.161A12.006,12.006,0,0,0,40.8,8.177l-.705-.02H20.157a12,12,0,0,0-11.98,11.3l-.02.705V65.684a12.005,12.005,0,0,0,11.3,11.984l.7.02h19.98A11.964,11.964,0,0,0,52.081,66.431l.02-.7V61.872a3.068,3.068,0,0,1,6.107-.416l.028.416v3.857A18.1,18.1,0,0,1,41.014,83.8l-.877.021H20.157A18.14,18.14,0,0,1,2.042,66.537l-.02-.854V20.161A18.14,18.14,0,0,1,19.3,2.041l.854-.02ZM85.076,40.706a3.068,3.068,0,0,1-.018,4.448L73.142,57.022a3.068,3.068,0,0,1-4.627-4l.3-.343,6.708-6.685H33.706a3.068,3.068,0,0,1-.416-6.107l.416-.028H75.528l-6.715-6.682a3.069,3.069,0,0,1-.306-3.993l.3-.345a3.068,3.068,0,0,1,3.993-.306l.345.3Z"
                            transform="translate(-2.022 -2.021)"
                            fillRule="evenodd"
                            opacity="1"
                            fill="#FF9494"
                          />
                        </svg>
                        {translation("dashboard_logout")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          heading={translation("dashboard_logout")}
          description={translation("logout_modal_description")}
          onClickOkay={() => handleLogOut()}
          onClickCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default Header;
