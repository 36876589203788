const ROUTES = {
  auth: {
    LOGIN: "/auth/login",
    FORGOT_PASSWORD: "/auth/send-otp",
    VERIFY_OTP: "/auth/otp-verification",
    RESET_PASSWORD: "/auth/reset-password",
    ACTIVATE_DEACTIVATE_USER: "/auth/status/update",
    SIGNUP: "/auth/signup",
    MATCH_TOKEN: "/auth/match-token",
  },

  user: {
    CONTACT_US: "/user/contact-us",
    UPDATE_PROFILE: "/user/update-profile",
    CHANGE_PASSWORD: "/user/change-password",
    UPDATE_PROFILE_IMAGE: "/user/update-profile-image",
    UPDATE_STATUS: "/user/update-status",
    GET_INFO: "/user/get-info",
    GET_USER_LIST: "/user/user-list",
    UPDATE_MEMBERSHIP_PLAN: "/user/update-plan",
    UPDATE_TAX_PLAN: "/user/update-tax",
    GET_UNREAD_NOTIFICATION: "/user/unread-notification",
    UPDATE_UNREAD_NOTIFICATION: "/user/update-notification",
    LOGIN_AS_USER: "/user/admin-customer-login",
    ADMIN_LOGIN_BY_TOKEN: "/user/admin-login",
    GET_USER_STATUS: "/user/user-status",
    GET_USER_MEMBERSHIP: "/user/user-membership",
    CREATE_AUTHORIZE_IDs: "/user/create-authorize-id",
    CREATE_STRIPE_ID: "/user/create-stripe-id",
  },
  orders: {
    GET_RATE_LIST: "/order/rate-list",
    PLACE_ORDER: "/order/place-order",
    GET_ORDER_LIST: "/order/order-list",
    UPDATE_PRODUCT_STATUS: "/order/update-product",
    GET_ORDER_INFO: "/order/order-detail",
    UPDATE_ORDER_STATUS: "/order/update-order",
    USER_ORDER: "/order/my-orders",
    USER_PRODUCTS: "/order/product-list",
    UPLOAD_NEW_IMAGES: "/order/update-order-images",
    ACTIVITY_HISTORY: "/order/activity-history",
    UPLOAD_TEMP_IMAGES: "/order/create-images",
    GET_TEMP_IMAGES: "/order/get-images",
    DELETE_SINGLE_IMAGE: "/order/delete-single-image",
    UPDATE_TEMP_IMAGE_DATA: "/order/update-image-data",
    UPDATE_REORDER: "/order/reorder",
    PLACE_TEMP_ORDER: "/order/place-temp-order",
    DELETE_SINGLE_PENDING_ORDER_IMAGE:
      "/order/delete-single-pending-order-image",
    DELETE_ORDER: "/order/delete-order",
    CHECK_ORDER_TIME_INTERVAL: "/order/check-order-time-interval",
  },
  payment: {
    CREATE_USER_PROFILE: "/payment/create-profile",
    USER_ORDER_PAYMENT: "/payment/order-payment",
    GET_PAYMENT_LIST: "/payment/payment-list",
    GET_FORM_TOKEN: "/payment/form-token",
    GET_PENDING_AMOUNT_TOKEN: "/payment/pending-token",
    UPDATE_PENDING_AMOUNT: "/payment/pending-amount",
    STRIPE_PAYMENT: "/payment/stripe-payment",
    STRIPE_PENDING_PAYMENT: "/payment/stripe-pending-payment",
    CHECK_PAYMENT_TIME_INTERVAL: "/payment/check-payment-time-interval",
    GET_ORDER_PENDING_AMOUNT: "/payment/amount",
    GET_PENDING_TOKEN: "/payment/get-pending-token",
  },
  uploadArtWork: {
    GET_PRESIGNEDURL: "/upload-artwork/get=presigned-url",
    PUSH_TO_S3: "/upload-artwork/upload-image",
  },
  admin: {
    DASHBOARD_COUNT: "/dashboard/dashboard-data",
    DASHBOARD_INFO: "/dashboard/dashboard-info",
  },
  artist: {
    DASHBOARD_COUNT: "/dashboard/artist-dashboard-data",
  },
  notification: {
    NOTIFICATION_LIST: "/notification/notification-list",
  },
  google: {
    SEARCH_PREDICTION: "/google/get-predictions/:searchText",
    PLACES_DETAIL: "/google/get-places-details/:placeId",
    PLACE_DETAILS: "/google/location-details",
  },
  sheet: {
    UPDATE_SHEET: "/sheet/update-sheet",
    DELETE_SHEET: "/sheet/delete-sheet",
    GET_SHEET_INFO: "/sheet/get-info",
    GET_SHEET_PRICES: "/sheet/get-sheet-prices",
  },
  package: {
    UPDATE_PACKAGE: "/package",
    DELETE_PACKAGE: "/package",
    GET_PACKAGE_INFO: "/package",
  },
  sheetWeight: {
    UPDATE_SHEETWEIGHT: "/sheet-weight",
    DELETE_SHEETWEIGHT: "/sheet-weight",
    GET_SHEETWEIGHT_INFO: "/sheet-weight",
  },
  setting: {
    UPDATE_SETTING: "/setting/update-setting",
    GET_SETTING_INFO: "/setting/get-setting",
    GET_ADMIN_SEETINGS:"/admin-settings/get-admin-settings"
  },
};

export default ROUTES;
